import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';

const QuickSettleAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
    {
      path: '/apps/quick-settle/quick-settle-users/:quickSettleUserId',
      auth: authRoles.quicksettle,
			component: React.lazy(() => import('./quick-settle-user-management/QuickSettleUserManagement'))
    },
    {
      path: '/apps/quick-settle/quick-settle-users',
      auth: authRoles.quicksettle,
			component: React.lazy(() => import('./quick-settle-users/QuickSettleUsers'))
    },
    {
      path: '/apps/quick-settle/quick-settle-settlements/:quickSettleSettlementId',
      auth: authRoles.quicksettle,
			component: React.lazy(() => import('./quick-settle-settlement-management/QuickSettleSettlementManagement'))
    },
    {
      path: '/apps/quick-settle/quick-settle-settlements/',
      auth: authRoles.quicksettle,
			component: React.lazy(() => import('./quick-settle-settlements/QuickSettleSettlements'))
    },
    {
      path: '/apps/quick-settle/quick-settle-repayments/:quickSettleRepaymentId',
      auth: authRoles.quicksettle,
			component: React.lazy(() => import('./quick-settle-repayment-management/QuickSettleRepaymentManagement'))
    },
    {
      path: '/apps/quick-settle/quick-settle-repayments/',
      auth: authRoles.quicksettle,
			component: React.lazy(() => import('./quick-settle-repayments/QuickSettleRepayments'))
    },
    {
      path: '/apps/quick-settle/quick-settle-account-transfers/',
      auth: authRoles.quicksettle,
			component: React.lazy(() => import('./quick-settle-account-transfers/QuickSettleAccountTransfers'))
    },
    {
      path: '/apps/quick-settle',
      auth: authRoles.quicksettle,
			component: () => <Redirect to="/apps/quick-settle/quick-settle-users" />
		}
	]
};

export default QuickSettleAppConfig;
