import i18next from 'i18next';
import { authRoles } from 'app/auth';

import ko from './navigation-i18n/ko';
import en from './navigation-i18n/en';

i18next.addResourceBundle('ko', 'navigation', ko);
i18next.addResourceBundle('en', 'navigation', en);

const navigationConfig = [
	{
    id: 'applications',
		title: 'Applications',
		translate: 'APPLICATIONS',
		type: 'group',
    icon: 'apps',
		children: [
      {
				id: 'quickSettle',
				title: 'QuickSettle',
				translate: 'QUICK_SETTLE',
				type: 'collapse',
				icon: 'check_box',
        url: '/apps/quick-settle',
        auth: authRoles.quicksettle,
				children: [
          {
						id: 'quick-settle-users',
						title: '고객 관리',
						type: 'item',
            url: '/apps/quick-settle/quick-settle-users',
            auth: authRoles.quicksettle,
						exact: true
          },
          {
						id: 'quick-settle-settlements',
						title: '정산 관리',
						type: 'item',
            url: '/apps/quick-settle/quick-settle-settlements',
            auth: authRoles.quicksettle,
						exact: true
          },
          {
						id: 'quick-settle-repayments',
						title: '상환 관리',
						type: 'item',
            url: '/apps/quick-settle/quick-settle-repayments',
            auth: authRoles.quicksettle,
						exact: true
          },
          {
						id: 'quick-settle-account-transfers',
						title: '이체 관리',
						type: 'item',
            url: '/apps/quick-settle/quick-settle-account-transfers',
            auth: authRoles.quicksettle,
						exact: true
					},
				]
			},
			{
				id: 'terms',
				title: 'Terms',
				translate: 'TERMS',
				type: 'collapse',
				icon: 'check_box',
        url: '/apps/terms',
        auth: authRoles.terms,
				children: [
          {
						id: 'terms-list',
						title: '약관 조회',
						type: 'item',
            url: '/apps/terms/terms-list',
            auth: authRoles.terms,
						exact: true
					},
					{
						id: 'terms-management-list',
						title: '약관 관리',
						type: 'item',
            url: '/apps/terms/terms-management-list',
            auth: authRoles.terms,
						exact: true
					},
					{
						id: 'terms-management-new',
						title: '약관 생성',
						type: 'item',
            url: '/apps/terms/terms-management-list/new',
            auth: authRoles.terms,
						exact: true
					},
				]
      },
		]
  },

  {
		type: 'divider',
		id: 'divider-2'
  },
];

export default navigationConfig;
