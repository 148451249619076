import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';

const TermsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
    {
      path: '/apps/terms/terms-list/:termsId',
      auth: authRoles.terms,
			component: React.lazy(() => import('./terms/Terms'))
		},
		{
      path: '/apps/terms/terms-list',
      auth: authRoles.terms,
			component: React.lazy(() => import('./terms-list/TermsList'))
    },
    {
      path: '/apps/terms/terms-agreed-users/:termsId',
      auth: authRoles.terms,
			component: React.lazy(() => import('./terms-agreed-users/TermsAgreedUsers'))
    },
    {
      path: '/apps/terms/terms-users-agreements/:userId',
      auth: authRoles.terms,
			component: React.lazy(() => import('./terms-users-agreements/TermsUsersAgreements'))
		},
		{
      path: '/apps/terms/terms-management-list/:termsId',
      auth: authRoles.terms,
			component: React.lazy(() => import('./terms-management/TermsManagement'))
		},
		{
      path: '/apps/terms/terms-management-list',
      auth: authRoles.terms,
			component: React.lazy(() => import('./terms-management-list/TermsManagementList'))
    },
		{
      path: '/apps/terms',
      auth: authRoles.terms,
			component: () => <Redirect to="/apps/terms/terms-list" />
		}
	]
};

export default TermsAppConfig;
