/**
 * Authorization Roles
 */
const authRoles = {
	admin: ['admin'],
	// staff: ['admin', 'staff'],
  // user: ['admin', 'staff', 'user'],
  quicksettle: ['admin', 'quicksettle'],
  terms: ['admin', 'terms'],
	onlyGuest: []
};

export default authRoles;
