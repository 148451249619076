import TermsAppConfig from './terms/TermsAppConfig';
import QuickSettleAppConfig from './quick-settle/QuickSettleAppConfig';


const appsConfigs = [
  TermsAppConfig,
  QuickSettleAppConfig
];

export default appsConfigs;
